/**
 * This file is used to lazy load our "root" components.
 * Anything imported in this file should have a corresponding
 * Rails view that renders it.
 *
 * The `REGISTRY` keys map to the component names passed to the
 * `render_react_component` helper in the Rails views.
 *
 * When adding new components to the registry, make sure to use
 * the `lazyNamedImport` function for named exports and `lazyDefaultImport`
 * for default exports.
 */

import { lazy } from 'react';

// Import helpers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyDefaultImport = (importFn: () => Promise<any>) => lazy(importFn);

// React.lazy only supports default exports, so we need to use a workaround for named exports
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyNamedImport = (importFn: () => Promise<any>, name: string) =>
  lazy(() => importFn().then((module) => ({ default: module[name] })));

export const REGISTRY = {
  AcademyCrossSell: lazyNamedImport(() => import('./features/cross-sells/components'), 'AcademyCrossSell'),
  AcademyCertificationsV2Router: lazyNamedImport(
    () => import('./features/academies/certifications-v2/routes'),
    'AcademyCertificationsV2Router',
  ),
  EmailAcademySettings: lazyNamedImport(
    () => import('./features/academies/settings/admin/components/EmailAcademySettings'),
    'EmailAcademySettings',
  ),
  GeneralAcademySettings: lazyNamedImport(
    () => import('./features/academies/settings/admin/components/GeneralAcademySettings'),
    'GeneralAcademySettings',
  ),
  CertificationAcademySettings: lazyNamedImport(
    () => import('./features/academies/settings/admin/components/CertificationAcademySettings'),
    'CertificationAcademySettings',
  ),
  AcademyLearnerCertifications: lazyNamedImport(
    () => import('./components/academies/learner/AcademyLearnerCertifications'),
    'AcademyLearnerCertifications',
  ),
  DedicatedLearnerSearchPage: lazyNamedImport(
    () =>
      import(
        './features/customer-learning-cloud-hub/features/dedicated-learner-search-page/components/DedicatedLearnerSearchPage'
      ),
    'DedicatedLearnerSearchPage',
  ),
  AdminProfileRoute: lazyNamedImport(() => import('./elc/features/manager-dashboard'), 'AdminProfileRoute'),
  AdminSettingsUserList: lazyNamedImport(() => import('./features/admin/settings'), 'AdminSettingsUserList'),
  AdvancedSearchPage: lazyNamedImport(() => import('./features/learner-search'), 'AdvancedSearchPage'),
  ChallengeOverviewRouter: lazyNamedImport(
    () => import('./elc/features/admin-content-overview'),
    'ChallengeOverviewRouter',
  ),
  ChallengeReviewerHome: lazyNamedImport(
    () => import('./elc/features/challenge-reviewer-dashboard'),
    'ChallengeReviewerHome',
  ),
  CLCProfilePage: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/routes/learner/CLCProfilePage'),
    'CLCProfilePage',
  ),
  CMSFileViewer: lazyNamedImport(() => import('./features/cms'), 'CMSFileViewer'),
  CMSExternalPreviewPage: lazyNamedImport(() => import('./features/cms'), 'CMSExternalPreviewPage'),
  CMSRouter: lazyNamedImport(() => import('./features/cms'), 'CMSRouter'),
  CommunityPreview: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/theming/admin/components/Previews/CommunityPreview'),
    'CommunityPreview',
  ),
  ContentProviderDevelopmentRouter: lazyNamedImport(
    () => import('./features/learner-content-provider-development'),
    'ContentProviderDevelopmentRouter',
  ),
  CustomerCloudForgotPassword: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/login-and-registration'),
    'CustomerCloudForgotPassword',
  ),
  CustomerCloudLogin: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/login-and-registration'),
    'CustomerCloudLogin',
  ),
  CustomerCloudRegister: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/login-and-registration'),
    'CustomerCloudRegister',
  ),
  CustomerCloudResetPassword: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/login-and-registration'),
    'CustomerCloudResetPassword',
  ),
  CustomerLearningCloudHubRouter: lazyNamedImport(
    () =>
      import(
        './features/customer-learning-cloud-hub/routes/CustomerLearningCloudHubRouter/CustomerLearningCloudHubRouter'
      ),
    'CustomerLearningCloudHubRouter',
  ),
  CustomerCloudNoAccess: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/features/login-and-registration'),
    'CustomerCloudNoAccess',
  ),
  DefaultAcademyErrorPage: lazyNamedImport(
    () => import('./components/academies/common/DefaultAcademyErrorPage'),
    'DefaultAcademyErrorPage',
  ),
  DevelopmentContentRouter: lazyNamedImport(() => import('./features/learner-development'), 'DevelopmentContentRouter'),
  GuideOverviewRouter: lazyNamedImport(() => import('./elc/features/admin-content-overview'), 'GuideOverviewRouter'),
  ELCIntegrationsRouter: lazyNamedImport(() => import('./features/elc/integrations'), 'ELCIntegrationsRouter'),
  CLCIntegrationsRouter: lazyNamedImport(() => import('./features/clc/integrations'), 'CLCIntegrationsRouter'),
  LearnerCommunityRouter: lazyNamedImport(
    () => import('./features/customer-learning-cloud-hub/routes/learner/LearnerCommunityRouter'),
    'LearnerCommunityRouter',
  ),
  LearnerEventsHome: lazyNamedImport(() => import('./elc/features/learner-events'), 'LearnerEventsHome'),
  LearnerCompanyRouter: lazyNamedImport(() => import('./elc/features/learner'), 'LearnerCompanyRouter'),
  LearnerHome: lazyNamedImport(() => import('./elc/features/learner'), 'LearnerHome'),
  LearnerProfile: lazyNamedImport(() => import('./elc/features/learner'), 'LearnerProfile'),
  LearnerResources: lazyNamedImport(() => import('./elc/features/learner'), 'LearnerResources'),
  LearnerSavedContent: lazyNamedImport(() => import('./elc/features/learner-saved-content'), 'LearnerSavedContent'),
  LearnerSettings: lazyNamedImport(() => import('./elc/features/learner'), 'LearnerSettings'),
  LibraryRouter: lazyNamedImport(() => import('./features/library'), 'LibraryRouter'),
  LoginWithUpsell: lazyNamedImport(() => import('./components/LoginWithUpsell'), 'LoginWithUpsell'),
  ManagerDashboardRouter: lazyNamedImport(() => import('./elc/features/manager-dashboard'), 'ManagerDashboardRouter'),
  PathOverviewRouter: lazyNamedImport(() => import('./elc/features/admin-content-overview'), 'PathOverviewRouter'),
  ReportingRouter: lazyNamedImport(() => import('./elc/features/reporting'), 'ReportingRouter'),
  SavedReports: lazyNamedImport(
    () => import('./features/academies/training-analytics/routes/SavedReports'),
    'SavedReports',
  ),
  SkillsRouter: lazyNamedImport(() => import('./features/elc/skills'), 'SkillsRouter'),
  TaskAssignmentPreview: lazyNamedImport(() => import('./elc/features/admin-guides'), 'TaskAssignmentPreview'),
  TrainingAnalyticsPage: lazyNamedImport(
    () => import('./features/academies/training-analytics'),
    'TrainingAnalyticsPage',
  ),
  UserConsentModal: lazyNamedImport(() => import('./components/common/UserConsentModal'), 'UserConsentModal'),
  VersionHistoryPage: lazyNamedImport(() => import('./elc/features/admin-guides/routes'), 'VersionHistoryPage'),
  AcademyAdminAnalyticsV2: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-analytics-v2')),
  AcademyAdminCertification: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-certification'),
  ),
  AwardedAcademyCertificationPreview: lazyDefaultImport(
    () => import('./features/academies/certifications-v2/admin/components/AwardedAcademyCertificationPreview'),
  ),
  AcademyAdminCertificationEnrollments: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-certification-enrollments'),
  ),
  AcademyAdminCertifications: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-certifications'),
  ),
  AcademyAdminCollectionContent: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-collection-content'),
  ),
  AcademyAdminContent: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-content')),
  AcademyAdminContentSettings: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-content-settings'),
  ),
  Content: lazyDefaultImport(() => import('./features/academies/content/Content')),
  AcademyAdminDataViz: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-data-viz')),
  AcademyAdminEcommercePromos: lazyDefaultImport(
    () => import('./components/academies/admin/ecommerce/academy-admin-ecommerce-promos'),
  ),
  AcademyAdminEcommercePurchases: lazyDefaultImport(
    () => import('./components/academies/admin/ecommerce/academy-admin-ecommerce-purchases'),
  ),
  EcommerceAcademySettings: lazyNamedImport(
    () => import('./features/academies/settings/admin/components/EcommerceAcademySettings'),
    'EcommerceAcademySettings',
  ),
  AcademyAdminEmailPreview: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-email-preview')),
  AcademyAdminPreview: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-preview')),
  AcademyAdminTheming: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-theming')),
  AcademyAdminTextOverrides: lazyNamedImport(
    () => import('./components/academies/admin/AcademyAdminTextOverrides'),
    'AcademyAdminTextOverrides',
  ),
  AcademyAdminTrainingEnrollments: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-trainings-overview'),
  ),
  AcademyAdminTrainings: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-trainings')),
  AcademyAdminUserAnalytics: lazyDefaultImport(
    () => import('./components/academies/admin/academy-admin-user-analytics'),
  ),
  AcademyAdminUsers: lazyDefaultImport(() => import('./components/academies/admin/academy-admin-users')),
  AcademyAdminPages: lazyDefaultImport(() => import('./features/academies/pages/AcademyAdminPages')),
  AcademyCustomPage: lazyDefaultImport(() => import('./features/academies/pages/AcademyCustomPage')),
  AcademyCheckout: lazyDefaultImport(() => import('./components/academies/learner/academy-checkout')),
  AcademyEventsV2Router: lazyDefaultImport(() => import('./features/academies/events-v2/routes/AcademyEventsV2Router')),
  AcademyLearnerCertification: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-certification'),
  ),
  AcademyLearnerCollection: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-collection'),
  ),
  AcademyLearnerContentOverview: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-content-overview'),
  ),
  AcademyLearnerCustomPage: lazyNamedImport(() => import('./features/learner-pages'), 'AcademyLearnerCustomPage'),
  AcademyLearnerEvent: lazyDefaultImport(() => import('./components/academies/learner/academy-learner-event')),
  AcademyLearnerEventOnDemandContent: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-event-on-demand-content'),
  ),
  AcademyLearnerEvents: lazyDefaultImport(() => import('./components/academies/learner/academy-learner-events')),
  AcademyLearnerForgotPassword: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-forgot-password'),
  ),
  AcademyLearnerHome: lazyDefaultImport(() => import('./components/academies/learner/academy-learner-home')),
  AcademyLearnerInvitationDeleted: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-invitation-deleted'),
  ),
  AcademyLearnerLogin: lazyDefaultImport(() => import('./components/academies/learner/academy-learner-login')),
  AcademyLearnerLoginAcceptTerms: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-login-accept-terms'),
  ),
  AcademyLearnerMyOrders: lazyDefaultImport(
    () => import('./components/academies/learner/orders/academy-learner-my-orders'),
  ),
  AcademyLearnerMyTrainings: lazyDefaultImport(
    () => import('./components/academies/learner/my-trainings/my-trainings'),
  ),
  AcademyLearnerProfileMyTrainings: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-profile-my-trainings'),
  ),
  AcademyLearnerResetPassword: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-reset-password'),
  ),
  AcademyLearnerSSOFailure: lazyDefaultImport(
    () => import('./components/academies/learner/academy-learner-sso-failure'),
  ),
  AdminContentEditor: lazyDefaultImport(() => import('./components/admin-content/admin-content-editor')),
  AdminInternalDataViz: lazyDefaultImport(() => import('./components/admin-data-viz/admin-internal-data-viz')),
  AdminTrainingClass: lazyDefaultImport(() => import('./components/training_classes/v2/admin-training-class')),
  AdminTrainingHome: lazyDefaultImport(() => import('./components/admin-guide-viewer/admin-training-home')),
  AdminTrainingWorkflowViewer: lazyDefaultImport(
    () => import('./components/admin-training-workflow-viewer/admin-training-workflow-viewer'),
  ),
  AdminTrashViewer: lazyDefaultImport(() => import('./components/admin-trash-viewer/admin-trash-viewer')),
  AdvancedSearchLanding: lazyDefaultImport(() => import('./components/advanced-search/advanced-search-landing')),
  AdvancedSearchEmbed: lazyNamedImport(
    () => import('./features/learner-search/components/AdvancedSearchEmbed'),
    'AdvancedSearchEmbed',
  ),
  AllCertificationsEditor: lazyDefaultImport(
    () => import('./components/enterprise-setup/all-certifications-editor/all-certifications-editor'),
  ),
  AllGroupsEditor: lazyDefaultImport(() => import('./components/enterprise-setup/all-groups-editor/all-groups-editor')),
  AllObjectTagsEditor: lazyDefaultImport(() => import('./components/object-tags/all-object-tags-editor')),
  AllRolesEditor: lazyDefaultImport(() => import('./components/enterprise-setup/roles/all-roles-editor')),
  AllUsersEditor: lazyDefaultImport(() => import('./components/enterprise-setup/all-users-editor/all-users-editor')),
  AssignmentDownloader: lazyDefaultImport(() => import('./components/guide-previewer/assignment-downloader')),
  AwardedAcademyCertificationLanding: lazyDefaultImport(
    () => import('./components/academies/common/awarded-academy-certification-landing'),
  ),
  BambooCallback: lazyDefaultImport(() => import('./components/enterprise-setup/integrations-editor/BambooCallback')),
  CertificationPage: lazyDefaultImport(() => import('./components/academies/admin/CertificationPage')),
  CertificationPreviewer: lazyDefaultImport(
    () => import('./components/enterprise-setup/certification-previewer/certification-previewer'),
  ),
  ContentIndexPage: lazyDefaultImport(() => import('./features/academies/content-index-page/ContentIndexPage')),
  ChallengeAdminReview: lazyDefaultImport(() => import('./components/admin-challenge-viewer/challenge-admin-review')),
  ChallengeEditor: lazyDefaultImport(() => import('./components/challenges/challenge-editor')),
  ChallengeEndUserView: lazyDefaultImport(() => import('./components/challenges/challenge-end-user-view')),
  ChallengePreviewer: lazyDefaultImport(() => import('./components/challenges/challenge-previewer')),
  ChallengeReviewerAssignment: lazyDefaultImport(() => import('./components/challenges/challenge-reviewer-assignment')),
  Document: lazyDefaultImport(() => import('./components/document/document')),
  DownloadPdf: lazyDefaultImport(() => import('./components/guide-previewer/download-pdf')),
  DynamicGroupsEditor: lazyDefaultImport(() => import('./components/dynamic-groups/dynamic-groups-editor')),
  DynamicGroupsEditorInternal: lazyDefaultImport(
    () => import('./components/dynamic-groups/dynamic-groups-editor-internal'),
  ),
  EmailSetup: lazyDefaultImport(() => import('./components/enterprise-setup/email-setup')),
  EmbedContent: lazyDefaultImport(() => import('./components/embed/embed-content')),
  EmbedHeader: lazyDefaultImport(() => import('./components/common/embed-header')),
  EmbedSearch: lazyDefaultImport(() => import('./components/embed/embed-search')),
  EnterpriseSettingsRouter: lazyNamedImport(
    () => import('./features/enterprise-settings/routes'),
    'EnterpriseSettingsRouter',
  ),
  ExternalTraining: lazyDefaultImport(() => import('./guide/external_training')),
  ExternalTrainingAdminReview: lazyDefaultImport(() => import('./components/academies/admin/external_training_review')),
  ForgotPassword: lazyDefaultImport(() => import('./components/login/forgot_password')),
  Go1Router: lazyNamedImport(() => import('./features/go1/routes/Go1Router'), 'Go1Router'),
  GuideAssignmentReminder: lazyDefaultImport(
    () => import('./components/guide_assignment_reminder/guide_assignment_reminder'),
  ),
  GuideAssignmentReviewer: lazyDefaultImport(
    () => import('./components/guide-assignment-reviewer/guide-assignment-reviewer'),
  ),
  GuideAssignmentSubmissions: lazyDefaultImport(
    () => import('./components/guide-assignment-submissions/guide-assignment-submissions'),
  ),
  GuideAssignmentViewer: lazyDefaultImport(
    () => import('./components/guide-assignment-viewer/guide-assignment-viewer'),
  ),
  GuideEditor: lazyDefaultImport(() => import('./components/guide-editor/guide-editor')),
  GuidePreviewer: lazyDefaultImport(() => import('./components/guide-previewer/guide-previewer')),
  GuideStatsPage: lazyDefaultImport(() => import('./components/guide-assignment-submissions/guide-stats-page')),
  GuideSubmissions: lazyDefaultImport(() => import('./components/guide-submissions/GuideSubmissions')),
  HelpViewer: lazyDefaultImport(() => import('./components/help-viewer/help-viewer')),
  HorizontalNavbar: lazyDefaultImport(() => import('./components/navigation/horizontal-navbar')),
  InstantLoginBanner: lazyDefaultImport(() => import('./components/common/instant-login-banner')),
  ItemFolderPane: lazyDefaultImport(() => import('./components/common/item-folder-pane')),
  Login: lazyDefaultImport(() => import('./components/login/login')),
  Notification: lazyDefaultImport(() => import('./components/notification/notification')),
  OAuthAuthorizationScopes: lazyNamedImport(
    () => import('./features/oauth-doorkeeper/components/OAuthAuthorizationScopes'),
    'OAuthAuthorizationScopes',
  ),
  PathViewRouter: lazyNamedImport(
    () => import('./elc/features/content-view-path/routes/PathViewRouter'),
    'PathViewRouter',
  ),
  Register: lazyDefaultImport(() => import('./components/register/register')),
  RegisterStep2: lazyDefaultImport(() => import('./components/register/register-step2')),
  ReportBuilder: lazyDefaultImport(() => import('./components/reporting/builder/report-builder')),
  ReportingAllCertifications: lazyDefaultImport(
    () => import('./components/reporting-all-certifications/reporting-all-certifications'),
  ),
  ReportingAllGroups: lazyDefaultImport(() => import('./components/reporting-all-groups/reporting-all-groups')),
  ReportingAllGuides: lazyDefaultImport(() => import('./components/reporting-all-guides/reporting-all-guides')),
  ReportingAllUsers: lazyDefaultImport(() => import('./components/reporting-all-users/reporting-all-users')),
  ReportingCustom: lazyDefaultImport(() => import('./components/reporting-custom/reporting-custom')),
  ReportingSingleCertification: lazyDefaultImport(
    () => import('./components/reporting-single-certification/reporting-single-certification'),
  ),
  ReportingSingleGroupGuides: lazyDefaultImport(
    () => import('./components/reporting-single-group/reporting-single-group-guides'),
  ),
  ReportingSingleGroupUsers: lazyDefaultImport(
    () => import('./components/reporting-single-group/reporting-single-group-users'),
  ),
  ResetPassword: lazyDefaultImport(() => import('./components/login/reset_password')),
  ResourceViewerFromEditor: lazyDefaultImport(
    () => import('./components/universities/resources/resource_viewer_from_editor'),
  ),
  ResourceViewerFromSeriesAssignment: lazyDefaultImport(
    () => import('./components/universities/resources/resource_viewer_from_series_assignment'),
  ),
  ResourceViewerFromSeriesEditorPreview: lazyDefaultImport(
    () => import('./components/universities/resources/resource_viewer_from_series_editor_preview'),
  ),
  ResourceViewerFromUniversity: lazyDefaultImport(
    () => import('./components/universities/resources/resource_viewer_from_university'),
  ),
  ResourceViewerFromUniversitySeriesPreview: lazyDefaultImport(
    () => import('./components/universities/resources/resource_viewer_from_university_series_preview'),
  ),
  RoleEditor: lazyDefaultImport(() => import('./components/enterprise-setup/roles/role-editor')),
  SAMLEnterprisePicker: lazyDefaultImport(() => import('./components/login/saml_enterprise_picker')),
  ScormSubmissions: lazyDefaultImport(() => import('./components/guide-submissions/scorm-submissions')),
  ShareLink: lazyDefaultImport(() => import('./components/share_link/share_link')),
  SingleGroupEditor: lazyDefaultImport(
    () => import('./components/enterprise-setup/all-groups-editor/single-group-editor'),
  ),
  Task: lazyDefaultImport(() => import('./components/task/task')),
  TaskAssignmentReviewer: lazyDefaultImport(
    () => import('./components/task_assignment_reviewer/task_assignment_reviewer'),
  ),
  TaskEditor: lazyDefaultImport(() => import('./components/task_editor/task_editor')),
  TaskPreviewer: lazyDefaultImport(() => import('./components/task_previewer/task_previewer')),
  TaskViewer: lazyDefaultImport(() => import('./components/task_viewer/task_viewer')),
  TrainingClassUserViewV2: lazyDefaultImport(
    () => import('./components/training_classes/v2/TrainingClassUserViewV2/TrainingClassUserViewV2'),
  ),
  TrainingPage: lazyDefaultImport(() => import('./components/academies/admin/TrainingPage')),
  TrainingSeriesEditor: lazyDefaultImport(() => import('./components/training-series-editor/training-series-editor')),
  TrainingWorkflowEditor: lazyDefaultImport(
    () => import('./components/training-workflow-editor/training-workflow-editor'),
  ),
  TrainingWorkflowPreviewer: lazyDefaultImport(
    () => import('./components/training-workflow-previewer/training-workflow-previewer'),
  ),
  University: lazyDefaultImport(() => import('./components/universities/university')),
  UniversityMembers: lazyDefaultImport(() => import('./components/universities/members/university-members')),
  UniversityResourceEditor: lazyDefaultImport(
    () => import('./components/universities/resources/university_resource_editor'),
  ),
  UniversityResourceViewer: lazyDefaultImport(
    () => import('./components/universities/resources/university_resource_viewer'),
  ),
  UserInvitationRegister: lazyDefaultImport(() => import('./components/user_invitation/user_invitation_register')),
  WorkflowsRouter: lazyNamedImport(
    () => import('./elc/features/workflows/routes/WorkflowsRouter/WorkflowsRouter'),
    'WorkflowsRouter',
  ),

  // Spacemountain
  SpaceMountainAccessRequestsIndex: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-access-requests-index'),
  ),
  SpaceMountainAssetLibrary: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-asset-library'),
  ),
  SpaceMountainCopyFolders: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-copy-folders')),
  SpaceMountainEnterpriseCertificationTemplates: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprise-certification-templates'),
  ),
  SpaceMountainEnterpriseEdit: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprise-edit'),
  ),
  SpaceMountainEnterpriseGo1Integration: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprise-go1-integration'),
  ),
  SpaceMountainEnterpriseMetrics: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprise-metrics'),
  ),
  SpaceMountainEnterpriseNew: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprise-new'),
  ),
  SpaceMountainEnterprises: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-enterprises')),
  SpaceMountainEnterprisesUsers: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-enterprises-users'),
  ),
  SpaceMountainGo1OnboardingForm: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-go1-onboarding-form'),
  ),
  SpaceMountainImportableContentEditor: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-importable-content-editor'),
  ),
  SpaceMountainImportItems: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-import-items')),
  SpaceMountainIndex: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-index')),
  SpaceMountainMetrics: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-metrics')),
  SpaceMountainPayingAdminEmails: lazyDefaultImport(
    () => import('./components/space-mountain/space-mountain-paying-admin-emails'),
  ),
  SpaceMountainSsoEditor: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-sso-editor')),
  SpaceMountainUserEdit: lazyDefaultImport(() => import('./components/space-mountain/space-mountain-user-edit')),
  SpaceStationApiMain: lazyDefaultImport(
    () => import('./components/space-mountain/space-station/space-station-api-main'),
  ),
  SpaceStationRequestsDashboard: lazyDefaultImport(
    () => import('./components/space-mountain/space-station/space-station-requests-dashboard'),
  ),

  SpaceMountainUpsellContentIndex: lazyDefaultImport(
    () => import('./components/space-mountain/upsell-content/upsell-content-index'),
  ),
  SpaceMountainUpsellContentLogin: lazyDefaultImport(
    () => import('./components/space-mountain/upsell-content/upsell-content-login-page'),
  ),

  // Other
  ErrorPage: lazyNamedImport(() => import('./features/common'), 'ErrorPage'),
  DebugPanelWrapper: lazyDefaultImport(() => import('./components/dev-tools/DebugPanelWrapper')),
  ActionMessageContainer: lazyDefaultImport(() => import('./components/widget/action-message-container')),
  WRModalsContainer: lazyDefaultImport(() => import('./components/wr-modals/wr-modals-container')),
};
